import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "admin-panel-user",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      userId: null,
      valid: false,
      citiesForUser: [],
      search: "",
      citiesIsLoading: false,
      userDTO: {
        email: "",
        password: "",
        repeatedPassword: "",
        fullName: "",
        superAdmin: false,
        cityRoles: []
      },
      dateScheduleMenu: false
    };
  },
  created() {
    this.searchCities();
    this.userId = this.$route.params.userId;
    let title =
      this.userId != null ? "Admin panel user details" : "Insert new admin panel user";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.userId != null) {
      ApiService.get(`api/ap-users`, `${this.userId}`)
        .then(response => {
          this.$log.debug("AP user: ", response.data);
          this.userDTO = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    searchCities() {
      // Items have already been loaded
      if (this.citiesForUser.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
          .then(res => {
            this.citiesForUser = res.data;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.citiesIsLoading = false));
    },
    removeCityFromList(item) {
      const index = this.userDTO.cityRoles.map(function(e) { return e.id; }).indexOf(item.id);
      if (index >= 0)
        this.userDTO.cityRoles.splice(index, 1);
    },
    previousState() {
      this.$router.go(-1);
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveUser(this.userDTO);
      }
    },
    saveUser(userDTO) {
      this.loading = true;
      if(userDTO.cityRoles != null && userDTO.cityRoles.length > 0 && typeof userDTO.cityRoles[0] != 'number'){
        let cities = userDTO.cityRoles.map((obj) => obj.id);
        console.log("CITY ROLES:" + cities);
        userDTO.cityRoles = cities;
      }
      if (userDTO.id != null) {
        ApiService.put(`api/ap-users`, userDTO)
          .then(response => {
            this.$log.debug("AP user updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/ap-users`, userDTO)
          .then(response => {
            this.$log.debug("AP user created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.userDTO.password === this.userDTO.repeatedPassword) || 'Password must match'
    },
    ...mapGetters(["currentUserPersonalInfo"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.clearFields();
          this.$emit("input", value);
        }
      }
    }
  }
};
